import React from 'react'
const Error = () => {
    return ( 
        <div className="verify-page" style={{height:"740px"}} >
        <h3 style={{marginTop:"7rem"}}>
          Something went wrong. 404
        </h3>
      </div>

     );
}
 
export default Error;