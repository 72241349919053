import './Navigation.css'
import React from 'react';

const Footer = () => {
    return ( 

        <div className="footer">
            <p> &#169; Copyright 2024 <br></br>
            All rights deserved. Powered by Elma Mujovic</p>
        </div>
     );
}
 
export default Footer;